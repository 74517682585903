<template>
  <ion-card class="m-1 mx-2">
    <ion-card-header>
      <ion-card-subtitle>
        <span>takuto</span>
      </ion-card-subtitle>
      <ion-card-title> title </ion-card-title>
    </ion-card-header>
    <ion-card-content class="d-flex flex-wrap">
      <span class="mr-1"><ion-icon :src="$i('calendar-number-outline')"></ion-icon> 2021-1-5 </span>
      <span class="mr-1"><ion-icon :src="$i('eye-outline')"></ion-icon> 11k </span>
      <span class="mr-1"><ion-icon :src="$i('thumbs-up-outline')"></ion-icon> 200 </span>
      <span class="mr-1"><ion-icon :src="$i('bookmark-outline')"></ion-icon> 20 </span>
    </ion-card-content>
  </ion-card>
</template>
